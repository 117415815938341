export const Socials = () => {
  return (
    <section>
      <nav class="bg-[#d1cfba8a] p-8 text-white">
        <ul class="flex justify-center gap-8 text-size-4xl">
          <li>
            <a
              class="flex aspect-ratio-square h-18 items-center justify-center rounded-full bg-pink-500 p-2"
              href="https://instagram.com/magic_nonsense"
              target="_blank"
              rel="noreferrer"
            >
              <i class="i-brands-instagram" />
            </a>
          </li>

          <li>
            <a
              class="flex aspect-ratio-square h-18 items-center justify-center rounded-full bg-gray p-2"
              href="mailto:info@magic-nonsense.com"
              target="_blank"
              rel="noreferrer"
            >
              <i class="i-solid-envelope" />
            </a>
          </li>
        </ul>
      </nav>
    </section>
  );
};
